"use client";

import * as React from "react";
import { BarChart, CreditCard, Clock, PhoneCallIcon } from "lucide-react";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { SignedIn, SignedOut, SignInButton } from "@clerk/nextjs";
import { AboutSection } from "@/components/common/about";
import { HeroSection } from "@/components/common/hero";
import { ProductDemoSection } from "@/components/common/screens";
import { UseCasesSection } from "@/components/common/usecases";
import { PricingTable } from "@/components/common/pricing-table";
export default function Component() {
  return <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white dark:from-gray-900 dark:to-gray-800" data-sentry-component="Component" data-sentry-source-file="page.tsx">
      <main className="container mx-auto px-4 py-16 space-y-24">
        <HeroSection data-sentry-element="HeroSection" data-sentry-source-file="page.tsx" data-sentry-element="HeroSection" />

        <ProductDemoSection data-sentry-element="ProductDemoSection" data-sentry-source-file="page.tsx" data-sentry-element="ProductDemoSection" />

        <UseCasesSection data-sentry-element="UseCasesSection" data-sentry-source-file="page.tsx" data-sentry-element="UseCasesSection" />

        <div>
          <h3 className="text-3xl font-bold text-center mb-12">
            What TidyCalls can do for you
          </h3>
          <div className="grid md:grid-cols-3 gap-8">
            <FeatureCard icon={<Clock className="w-10 h-10 text-blue-600" />} title="Focus on growing your business" description="Let TidyCalls screen your calls, so you can focus on growing your business. Just refer cold callers to speak with your AI, all the way from picking up to figuring out the caller's purpose and aligning with your preferences." data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
            <FeatureCard icon={<BarChart className="w-10 h-10 text-blue-600" />} title="Never miss an opportunity" description="Tools to keep track of how TidyCalls is handling your calls. You get a full dashboard of past performance and insights into how your instructions are working, with full history of interactions. No more 'Oh I forgot so-and-so called!'" data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
            <FeatureCard icon={<CreditCard className="w-10 h-10 text-blue-600" />} title="Straightforward Pricing" description="We have a pricing model that makes sense and scales with your business. Get a US, UK number included in your subscription - perfect for SME businesses wanting to go international." data-sentry-element="FeatureCard" data-sentry-source-file="page.tsx" data-sentry-element="FeatureCard" />
          </div>
        </div>

        <PricingTable state={{
        signedIn: false
      }} data-sentry-element="PricingTable" data-sentry-source-file="page.tsx" data-sentry-element="PricingTable" />

        <div className="text-center">
          <h3 className="text-3xl font-bold text-center mb-12">
            If you would like to discuss with our sales team
          </h3>
          <div>
            <Button variant="link" data-sentry-element="Button" data-sentry-source-file="page.tsx" data-sentry-element="Button">
              <PhoneCallIcon data-sentry-element="PhoneCallIcon" data-sentry-source-file="page.tsx" data-sentry-element="PhoneCallIcon" />
              <a href="tel:+447418344938">+447418344938</a>
            </Button>
            <Button variant="link" data-sentry-element="Button" data-sentry-source-file="page.tsx" data-sentry-element="Button">
              <PhoneCallIcon data-sentry-element="PhoneCallIcon" data-sentry-source-file="page.tsx" data-sentry-element="PhoneCallIcon" />
              <a href="tel:+358942551659">+358942551659</a>
            </Button>
          </div>
          <p>
            or email us at{" "}
            <a href="mailto:sales@tidycalls.com">sales@tidycalls.com</a>
          </p>
        </div>
        <AboutSection data-sentry-element="AboutSection" data-sentry-source-file="page.tsx" data-sentry-element="AboutSection" />

        <Card className="text-center bg-purple-100 dark:bg-gray-800" data-sentry-element="Card" data-sentry-source-file="page.tsx" data-sentry-element="Card">
          <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="page.tsx" data-sentry-element="CardHeader">
            <CardTitle className="text-xl" data-sentry-element="CardTitle" data-sentry-source-file="page.tsx" data-sentry-element="CardTitle">
              Get a moment of peace without losing a single opportunity with{" "}
              <a href="/#pricing" className="text-blue-600">
                TidyCalls
              </a>
              !
            </CardTitle>
            <CardDescription className="text-xl" data-sentry-element="CardDescription" data-sentry-source-file="page.tsx" data-sentry-element="CardDescription">
              Thanks to AI it does&apos;t cost us as much to process a call as
              hiring a full time team of vetted and individually instructed call
              center staff, and we are passing the savings on to you! Join
              fellow professionals who are already enjoying the benefits of
              TidyCalls. And look forward to the opportunities when the phone
              rings &#9996;
            </CardDescription>
          </CardHeader>
          <CardContent data-sentry-element="CardContent" data-sentry-source-file="page.tsx" data-sentry-element="CardContent">
            <Button data-sentry-element="Button" data-sentry-source-file="page.tsx" data-sentry-element="Button">
              <SignedIn data-sentry-element="SignedIn" data-sentry-source-file="page.tsx" data-sentry-element="SignedIn">
                <a href="/onboarding">Get Started</a>
              </SignedIn>
              <SignedOut data-sentry-element="SignedOut" data-sentry-source-file="page.tsx" data-sentry-element="SignedOut">
                <SignInButton data-sentry-element="SignInButton" data-sentry-source-file="page.tsx" data-sentry-element="SignInButton">
                  <a href="/onboarding">Get Started</a>
                </SignInButton>
              </SignedOut>
            </Button>
          </CardContent>
        </Card>
      </main>
    </div>;
}
interface FeatureCardProps {
  icon: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  title: string;
  description: string;
}
function FeatureCard({
  icon,
  title,
  description
}: FeatureCardProps) {
  return <Card data-sentry-element="Card" data-sentry-component="FeatureCard" data-sentry-source-file="page.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="page.tsx" data-sentry-element="CardHeader">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">{icon}</div>
          <CardTitle className="text-xl" data-sentry-element="CardTitle" data-sentry-source-file="page.tsx" data-sentry-element="CardTitle">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="page.tsx" data-sentry-element="CardContent">
        <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="page.tsx" data-sentry-element="CardDescription">{description}</CardDescription>
      </CardContent>
    </Card>;
}