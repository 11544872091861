import { Button } from "@/components/ui/button";
import Image from "next/image";
export function HeroSection() {
  return <section data-sentry-component="HeroSection" data-sentry-source-file="hero.tsx">
      <div className="container mx-auto px-4 py-16">
        <div className="flex flex-col lg:flex-row items-center gap-12">
          <div className="lg:w-1/2 space-y-6">
            <h1 className="text-4xl lg:text-5xl font-bold text-gray-900 dark:text-white leading-tight">
              Pick up the phone,{" "}
              <span className="text-blue-600 dark:text-blue-400">
                you know you&apos;ll want to.
              </span>
            </h1>
            <p className="text-xl text-gray-600 dark:text-gray-300">
              Distracted by irrelevant cold calls? But worried about missing
              business opportunities so you feel obligated to answer them?
            </p>
            <p className="text-lg text-gray-700 dark:text-gray-200">
              Stop worrying and let TidyCalls handle call screening for you!
              Receive only relevant calls, with other calls transcribed in your
              email for later reference.
            </p>
            <p className="text-lg text-gray-700 dark:text-gray-200">
              TidyCalls is your personal AI contact center, answering calls 24/7
              based on your spoken instructions. Powered by modern AI. No voice
              menus, just a natural conversation.
            </p>
            <div className="flex space-x-4">
              <Button size="lg" className="bg-blue-600 hover:bg-blue-700 text-white" data-sentry-element="Button" data-sentry-source-file="hero.tsx" data-sentry-element="Button">
                <a href="/onboarding">Get Started</a>
              </Button>
              <Button size="lg" variant="outline" data-sentry-element="Button" data-sentry-source-file="hero.tsx" data-sentry-element="Button">
                <a href="/contact">Book a Demo</a>
              </Button>
            </div>
          </div>
          <div className="lg:w-1/2">
            <div className="relative">
              {/* Subtle gradient background effect */}
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-100 to-purple-100 dark:from-blue-900/20 dark:to-purple-900/20 rounded-3xl opacity-20 blur-2xl"></div>

              {/* Main image */}
              <div className="relative">
                <Image src="/phone-call-1.jpg" // Update with your actual image path
              alt="Phone showing No Caller ID screen" width={600} height={400} className="rounded-xl shadow-2xl" priority data-sentry-element="Image" data-sentry-source-file="hero.tsx" data-sentry-element="Image" />

                {/* Caption card */}
                <div className="absolute bottom-4 left-4 right-4 bg-white/90 dark:bg-gray-800/90 backdrop-blur-sm rounded-lg p-4 shadow-lg">
                  <p className="text-sm font-medium text-gray-900 dark:text-white">
                    No more unwanted distractions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>;
}