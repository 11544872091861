import { Shield, Rocket, Clock } from "lucide-react";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
export function AboutSection() {
  return <section className="container mx-auto px-4" id="about" data-sentry-component="AboutSection" data-sentry-source-file="about.tsx">
      <h2 className="text-3xl font-bold text-center mb-12">About the team</h2>
      <p className="text-xl text-muted-foreground max-w-2xl mx-auto mb-12">
        We&apos;re empowering professionals to focus on what they do best. No
        hassle, no distractions. No missed opportunities. We have seen our share
        of poorly managed outsourced services - and want to ensure that your
        virtual receptionist understands your needs and always treats you and
        your business prospects with respect. With fair and transparent pricing
        to boot.
      </p>
      <div className="grid md:grid-cols-3 gap-8">
        <AboutCard icon={<Clock className="w-10 h-10 text-blue-600" />} title="Your time matters" description="We started TidyCalls because as business owners, we know first hand how cold calls affect your day. Everybody says 'I just want 5 minutes of your time'. They don't mean it. Turn the tables, let them talk to your virtual receptionist first - for as long as they like." data-sentry-element="AboutCard" data-sentry-source-file="about.tsx" data-sentry-element="AboutCard" />
        <AboutCard icon={<Shield className="w-10 h-10 text-blue-600" />} title="Data Protection" description="As Europeans, we value our privacy too. Rest easy knowing that we protect your data using industry best practices as if our freedom dependend on it. Because it does. You - and only you - get the full transcript of the call so you are not missing out on any opportunities and can take action at your leisure if needed." data-sentry-element="AboutCard" data-sentry-source-file="about.tsx" data-sentry-element="AboutCard" />
        <AboutCard icon={<Rocket className="w-10 h-10 text-blue-600" />} title="Scalable SaaS" description="A telco provider is happy to sell you a voice solution. But it comes with a huge contract and is probably not powered by state of the art AI. We're a small team of engineers who love to build and we're here to make your life easier. Because that's what we want for ourselves too. We built this service to be something that we would want to pay for ourselves." data-sentry-element="AboutCard" data-sentry-source-file="about.tsx" data-sentry-element="AboutCard" />
      </div>
    </section>;
}
function AboutCard({
  icon,
  title,
  description
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) {
  return <Card className="bg-white dark:bg-gray-800" data-sentry-element="Card" data-sentry-component="AboutCard" data-sentry-source-file="about.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="about.tsx" data-sentry-element="CardHeader">
        <div className="flex items-center space-x-4">
          <div className="flex-shrink-0">{icon}</div>
          <CardTitle className="text-xl" data-sentry-element="CardTitle" data-sentry-source-file="about.tsx" data-sentry-element="CardTitle">{title}</CardTitle>
        </div>
      </CardHeader>
      <CardContent data-sentry-element="CardContent" data-sentry-source-file="about.tsx" data-sentry-element="CardContent">
        <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="about.tsx" data-sentry-element="CardDescription">{description}</CardDescription>
      </CardContent>
    </Card>;
}