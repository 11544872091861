import { CalendarDaysIcon, HeadsetIcon, PhoneForwardedIcon } from "lucide-react";
import { DemoCard } from "./democard";
export function UseCasesSection() {
  return <section data-sentry-component="UseCasesSection" data-sentry-source-file="usecases.tsx">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-12">Use Cases</h2>
        <div className="grid gap-8 md:grid-cols-3">
          <DemoCard icon={<CalendarDaysIcon className="w-10 h-10 text-blue-600" />} title="Event sign up forms" description="Use your AI Contact Center number in event registration forms to screen the inevitable cold calls." steps={["Sign up for TidyCalls", "Save the number as your contact", "Autofill forms using your AI receptionist's number"]} imageSrc="/event-registration.png?height=200&width=300" imageAlt="Sign up form" data-sentry-element="DemoCard" data-sentry-source-file="usecases.tsx" data-sentry-element="DemoCard" />
          <DemoCard icon={<PhoneForwardedIcon className="w-10 h-10 text-blue-600" />} title="Voicemail" description="You can direct your calls to your AI receptionist, and it will act as your voicemail, only better!" steps={["Sign up for TidyCalls", "Scan the QR code to use your AI receptionist as voicemail", "That's it! You're already done."]} imageSrc="/tidycalls-qr.png?height=200&width=300" imageAlt="Direct your calls to your AI receptionist" data-sentry-element="DemoCard" data-sentry-source-file="usecases.tsx" data-sentry-element="DemoCard" />
          <DemoCard icon={<HeadsetIcon className="w-10 h-10 text-blue-600" />} title="SME business" description="Go international, with premium company locations" steps={["Use your AI receptionist as your company contact number, and go international", "Come across as premium with phone numbers like San Francisco, New York, London, and more", "Never miss an important call"]} imageSrc="/moshizen-operator-male-2.jpg?height=200&width=300" imageAlt="AI receptionist" data-sentry-element="DemoCard" data-sentry-source-file="usecases.tsx" data-sentry-element="DemoCard" />
        </div>
      </div>
    </section>;
}