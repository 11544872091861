import React from "react";
import Image from "next/image";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
interface DemoCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  steps: string[];
  imageSrc: string;
  imageAlt: string;
}
export function DemoCard({
  icon,
  title,
  description,
  steps,
  imageSrc,
  imageAlt
}: DemoCardProps) {
  return <Card className="flex flex-col h-full bg-white dark:bg-gray-800" data-sentry-element="Card" data-sentry-component="DemoCard" data-sentry-source-file="democard.tsx">
      <CardHeader data-sentry-element="CardHeader" data-sentry-source-file="democard.tsx" data-sentry-element="CardHeader">
        <div className="flex items-center space-x-4 mb-4">
          <div className="flex-shrink-0">{icon}</div>
          <CardTitle className="text-xl" data-sentry-element="CardTitle" data-sentry-source-file="democard.tsx" data-sentry-element="CardTitle">{title}</CardTitle>
        </div>
        <CardDescription data-sentry-element="CardDescription" data-sentry-source-file="democard.tsx" data-sentry-element="CardDescription">{description}</CardDescription>
      </CardHeader>
      <CardContent className="flex-grow flex flex-col" data-sentry-element="CardContent" data-sentry-source-file="democard.tsx" data-sentry-element="CardContent">
        <div className="mb-4 relative h-[200px] rounded-md overflow-hidden">
          <Image src={imageSrc} alt={imageAlt} fill className="rounded-md object-cover" data-sentry-element="Image" data-sentry-source-file="democard.tsx" data-sentry-element="Image" />
        </div>
        <ol className="list-decimal list-inside space-y-2 text-sm text-gray-600 dark:text-gray-300">
          {steps.map((step, index) => <li key={index}>{step}</li>)}
        </ol>
      </CardContent>
    </Card>;
}